import {
  Box,
  Heading,
  Flex,
  List,
  ListItem,
  Link,
  Text,
  Image,
  Spacer,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box
      as="footer"
      py="2.5rem"
      fontSize="0.875rem"
      maxW="1140px"
      w="100%"
      mx="auto"
    >
      <Flex
        w="100%"
        alignItems="center"
        justify="space-between"
        gap="2"
        marginX="auto"
        flexWrap="wrap"
      >
        <Box
          w={{ base: "100%", md: "39%" }}
          pb="2rem"
          mb="1.5rem"
          px={{ base: 6, sm: 10 }}
        >
          <Link href="/" isExternal>
            <Image
              w={["7rem", "9rem"]}
              src="https://www.icb.network/images/logoicon.png"
              alt="TemplatesKart"
            />
          </Link>
          <Text mt={2} color="gray.500" fontSize="sm" align={["left", "left"]}>
            ICB Network is an advanced blockchain, combining speed and safety.
            It is built on top of one of the most cutting-edge security features
            currently available.
          </Text>
        </Box>

        <Box
          w={{ base: "100%", md: "59%" }}
          pb="2rem"
          mb="1.5rem"
          px={{ base: 6, sm: 10 }}
        >
          <Flex
            flexWrap="wrap"
            alignItems="start"
            justifyContent="space-between"
          >
            <Box
              w={{ base: "100%", sm: "max-content", md: "max-content" }}
              mb={{ base: "1.5rem", lg: "0" }}
            >
              <Heading
                as="h5"
                color="gray.500"
                mb="0.5rem"
                fontSize="1rem"
                fontWeight="600"
                lineHeight="1.2rem"
              >
                COMPANY
              </Heading>
              <List lineHeight="2" justifyContent="center">
                <LinkItem
                  text="About us"
                  linkHref="https://www.icb.network/about"
                />
                <LinkItem
                  text="Careers "
                  isTag={true}
                  tagText="Coming Soon"
                  linkHref="/"
                />
                <LinkItem
                  text="Privacy Policy"
                  linkHref="https://www.icb.network/legal-docs/privacy-policy"
                />
                <LinkItem
                  text="Term & Conditions"
                  linkHref="https://docs.icb.network/legal-docs/terms-and-conditions"
                />
              </List>
            </Box>
            <Box
              w={{ base: "100%", sm: "max-content", md: "max-content" }}
              mb={{ base: "1.5rem", lg: "0" }}
            >
              <Heading
                as="h5"
                color="gray.500"
                mb="0.5rem"
                fontSize="1rem"
                fontWeight="600"
                lineHeight="1.2rem"
              >
                RESOURCES
              </Heading>
              <List lineHeight="2">
                <LinkItem
                  text="White Paper"
                  linkHref="https://docs.icb.network/developer-docs/white-paper"
                />
                <LinkItem
                  text="Documentation"
                  linkHref="https://docs.icb.network/"
                />
              </List>
            </Box>
            <Box
              w={{ base: "100%", sm: "max-content", md: "max-content" }}
              mb={{ base: "1.5rem", lg: "0" }}
            >
              <Heading
                as="h5"
                color="gray.500"
                mb="0.5rem"
                fontSize="1rem"
                fontWeight="600"
                lineHeight="1.2rem"
              >
                COMMUNITY
              </Heading>
              <List lineHeight="2">
                <LinkItem
                  text="Documentation"
                  linkHref="https://github.com/ICBNetwork"
                />
                <LinkItem
                  text="Join Us"
                  linkHref="https://linktr.ee/icbnetwork"
                />
              </List>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Flex maxW="auto" justify="center" mx="auto" alignItems="center" px={10}>
        <Text color="gray.600" fontSize="0.875rem" pl="0.5rem">
          &copy; Copyright © 2024 ICB network All Rights Reserved.
        </Text>
      </Flex>
    </Box>
  );
};

type LinkItemProps = {
  text?: string;
  isTag?: boolean;
  tagText?: string;
  linkHref?: string;
};

const LinkItem = ({
  text,
  isTag = false,
  tagText,
  linkHref,
}: LinkItemProps) => {
  return (
    <ListItem display="flex">
      <Link
        fontWeight="400"
        href={linkHref}
        color="rgba(113, 128, 150, 1)"
        _hover={{ color: "green.600" }}
      >
        {text}
      </Link>
      {isTag && (
        <Text
          as="span"
          bg="#008F94"
          px="0.25rem"
          display="inline-flex"
          alignItems="center"
          color="#fff"
          height="1.25rem"
          borderRadius="0.25rem"
          ml="0.25rem"
          mt="0.25rem"
          fontSize="0.75rem"
        >
          {tagText}
        </Text>
      )}
    </ListItem>
  );
};

export default Footer;
